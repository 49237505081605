import { commonIcons } from '../common/icon';
import { IIcon } from '../types/icon';

export const icon: IIcon = {
  ...commonIcons,
  delivery: 'deliveryPLK',
  order: 'book',
  plkRewards: 'plkRewards',
  rewards: 'plkRewards',
};
